<script setup>
import {ref} from 'vue'

const picture = ref()

const props = defineProps({
  assets: {
    type: Array,
    default: null,
  },
  alternative: {
    type: String,
    default: 'Pininfarina',
  },
  animate: {
    type: Boolean,
    default: true,
  },
  loading: {
    type: String,
    default: 'lazy',
    validator(value) {
      return ['eager', 'lazy'].includes(value)
    },
  },
})

function loadedImage(event) {
  if (picture.value) picture.value.classList.add('loaded')
}
</script>

<template>
  <div
    :class="{
      'basic-image': true,
      'animate-in-image': props.animate,
      'animate-out-fade': props.animate,
    }">
    <div class="basic-image-holder"></div>
    <ClientOnly>
      <picture
        v-if="props.assets != null"
        class="basic-image-picture"
        ref="picture">
        <source
          v-for="asset in filterPictureSourceTags(props.assets)"
          :srcset="asset.src"
          :type="asset.format"
          :media="asset.media" />
        <img
          v-for="asset in filterPictureImageTag(props.assets)"
          :src="asset.src"
          :alt="props.alternative"
          :loading="loading"
          @load="loadedImage" />
      </picture>
    </ClientOnly>
  </div>
</template>

<style lang="scss" scoped>
.basic-image {
  background-color: $color-gray-light;
  overflow: hidden;
  position: relative;
  user-select: none;

  .basic-image-holder {
    background-color: $color-gray-light;
    bottom: -100%;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
  }

  .basic-image-picture {
    opacity: 0;
    transition: opacity $transition-default-slow;

    &.loaded {
      opacity: 1;
    }
  }

  img {
    min-height: 100%;
    object-fit: cover;
  }
}
</style>
